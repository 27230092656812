import NextPage from 'types/NextPage';
import Replace from 'components/router/Replace';
import Route from 'constants/Route';
import useSession from 'hooks/useSession';

/**
 * Посадочная страница приложения.
 */
const IndexPage: NextPage = () => {
  const session = useSession();

  if (!session.isAuthenticated) {
    return <Replace to={Route.LOGIN} />;
  }

  return <Replace to={Route.CONTRACTS_PORTFOLIO} />;
};

export default IndexPage;
