/**
 * Маршрут приложения.
 */
enum Route {
  /**
   * Посадочная страница приложения.
   */
  HOME = '/',

  /**
   * Посадочная страница раздела входа в систему.
   */
  LOGIN = '/login',

  /**
   * Страница восстановления пароля.
   */
  RECOVERY = '/recovery',

  /**
   * Посадочная страница со списком договоров.
   */
  CONTRACTS = '/contracts',

  /**
   * Посадочная страница со списком.
   */
  CONTRACTS_PORTFOLIO = '/contracts/portfolio',

  /**
   * Страница результатов поиска договоров.
   */
  CONTRACTS_SEARCH = '/contracts/search',

  /**
   * Страница всех договоров в системе.
   */
  CONTRACTS_ALL = '/contracts/all',

  /**
   * Страница карточки должника.
   */
  CONTRACT = '/contract/:id',

  /**
   * Страница истории действий
   */
  CONTRACTS_HISTORY = '/contracts-history',

  /**
   * Страница оплаты ГП.
   */
  GOSFEE = '/gosfee',

  /**
   * Страница справочника судебных участков.
   */
  JUDICIAL_SECTOR = '/judicial-sector',

  /**
   * Страница реестра 1С УЗ.
   */
  REGISTER_UZ = '/1c-uz',

  /**
   * Страница мои действия.
   */
  TASKS = '/tasks',

  /**
   * Страница успешно обработанных договоров по фоновой задаче.
   */
  CONTRACTS_TASK = '/contracts/task/:id',

  /**
   * Страница со списком СУ.
   */
  COURTS = '/courts',
}

export default Route;
